import $ from 'jquery'
import Swiper from 'swiper';

$('.registration-checkbox').on('click', function (){
    $(this).toggleClass('active')
    if ($('.registration-checkbox.active').length == 2){
        $('#registration .button-block a.button').removeClass('disabled');
    }else{
        $('#registration .button-block a.button').addClass('disabled');
    }
})
$("#confirm").on('change', function (){
    $("#order-btn").toggleClass('disabled');
})


$("#products-menu-btn").on('click', function (){
    $('#products-submenu').addClass('active');
    return false;
})
$(".products-submenu-title").on('click', function (){
    $('#products-submenu').removeClass('active');
    return false;
})
$(".products-submenu-item-title").on('click', function (){
    $(".products-submenu-item").removeClass('active')
    $(this).parent().addClass('active')
})

$(".progress-point span").on('click', function (){
    window.location = $(this).parents('.progress-point').find('a').attr('href');
})

// if (document.body.clientWidth < 700){
//     const list = document.getElementById('home-mobile-list-scroll')
//     list.scrollTop = 1;
//     list.addEventListener('scroll', function(ev) {
//         let items = this.querySelectorAll('.item');
//         if (parseInt(this.scrollTop) == 0) {
//             this.scrollTop = items[items.length - 1].clientHeight;
//             document.getElementById('home-mobile-list-line').prepend(items[items.length - 1]);
//             this.scrollTop = 1;
//         } else if (this.scrollTop > this.scrollHeight - this.clientHeight - 1) {
//             document.getElementById('home-mobile-list-line').append(items[0]);
//         }
//         return false;
//     });
//
//     setTimeout(function (){
//         $('#home-mobile-list-scroll').stop().animate({'scrollTop': 100}, 500)
//     },100)
// }

$('#registration .button-block a.button').on('click', function (e){
    $('#registration .button-block a.button').addClass('disabled');
    $(".registration-input.error").removeClass('error');
    var em = ValidateEmail($('#email-input').val());
    if (!em){
        $('#email-input').parent().addClass('error');
    }
    if ($('#surname-input').val() == ''){
        $('#surname-input').parent().addClass('error');
    }
    if ($('#name-input').val() == ''){
        $('#name-input').parent().addClass('error');
    }
    if ($('#lastname-input').val() == ''){
        $('#lastname-input').parent().addClass('error');
    }
    if ($('#phone-input').val() == ''){
        $('#phone-input').parent().addClass('error');
    }
    if ($('#work-input').val() == ''){
        $('#work-input').parent().addClass('error');
    }
    if ($('#city-input').val() == ''){
        $('#city-input').parent().addClass('error');
    }


    if ($(".registration-input.error").length == 0){
        $.post( '/ajax/adduser.php', {
            email: $('#email-input').val(),
            surname: $('#surname-input').val(),
            name: $('#name-input').val(),
            lastname: $('#lastname-input').val(),
            phone: $('#phone-input').val(),
            work: $('#work-input').val(),
            city: $('#city-input').val(),
            address: $('#address-input').val(),
            company: $('#company-input').val(),
            contact: $('#contact-input').val()
        }, function( data ) {
            $('#registration .button-block a.button').removeClass('disabled');
            var json = JSON.parse(data)
            if (json.status == 'success'){
                $('#sccess-modal').addClass('active');
            }else{
                $("#error-message").html(json.Message);
                $("#error-message").show();
            }
        });
    }else{
        $('#registration .button-block a.button').removeClass('disabled');
    }
    return false;
})

function ValidateEmail(mail)
{
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
        return (true)
    }
    return (false)
}



